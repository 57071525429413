<template>
  <div v-loading="loading" class="layout">
    <div
      class="border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">Roles</h3>
      <div class="flex">
        <div class="mt-3 sm:mt-0 sm:ml-4 sm:mr-4">
          <label for="search_organizations" class="sr-only">Search</label>
          <div class="flex rounded-md shadow-sm">
            <div class="relative flex-grow focus-within:z-10">
              <div
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
              >
                <!-- Heroicon name: solid/search -->
                <svg
                  class="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <input
                type="text"
                name="search_organizations"
                id="search_organizations"
                class="search block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 sm:hidden 2xl:focus:ring-indigo-500"
                placeholder="Search"
              />
              <input
                type="text"
                name="search_organizations"
                id="search_organizations"
                class="search hidden w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:block sm:text-sm"
                placeholder="Search"
              />
            </div>
            <Menu>
              <div class="relative">
                <MenuButton
                  type="button"
                  class="focus:outline-none relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                >
                  <!-- Heroicon name: solid/sort-ascending -->
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M3 3a1 1 0 000 2h11a1 1 0 100-2H3zM3 7a1 1 0 000 2h5a1 1 0 000-2H3zM3 11a1 1 0 100 2h4a1 1 0 100-2H3zM13 16a1 1 0 102 0v-5.586l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 101.414 1.414L13 10.414V16z"
                    />
                  </svg>
                  <span class="ml-2">Sort</span>
                  <!-- Heroicon name: solid/chevron-down -->
                  <svg
                    class="ml-2.5 -mr-1.5 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </MenuButton>
                <transition
                  enter-active-class="transition duration-100 ease-out"
                  enter-from-class="transform scale-95 opacity-0"
                  enter-to-class="transform scale-100 opacity-100"
                  leave-active-class="transition duration-75 ease-out"
                  leave-from-class="transform scale-100 opacity-100"
                  leave-to-class="transform scale-95 opacity-0"
                >
                  <MenuItems
                    class="focus:outline-none absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white text-left shadow-lg ring-1 ring-black ring-opacity-5"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="sort-menu"
                  >
                    <div class="py-1" role="none">
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Name</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date modified</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a
                          href="#"
                          class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          >Date created</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </div>
            </Menu>
          </div>
        </div>
        <button
          type="button"
          @click="drawer.create = true"
          class="order-0 focus:outline-none inline-flex items-center rounded-md border border-transparent bg-purple-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:ring-purple-500 sm:order-1 sm:ml-3"
        >
          Create
        </button>
      </div>
    </div>
    <div class="flex flex-col" v-if="roles.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Organization
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Permissions
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="role in roles" :key="role.Id" class="text-left">
                  <td class="whitespace-nowrap px-6 py-4 text-left">
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ role.Name }}
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ role.Description }}
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                    {{
                      role.Organization !== null ? role.Organization.Name : null
                    }}
                  </td>
                  <td class="px-6 py-4 text-sm text-gray-500">
                    <span
                      v-for="permission in role.Permissions"
                      :key="permission.Id"
                      class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
                    >
                      {{ permission.Name }}
                    </span>
                  </td>
                  <td
                    class="flex space-x-3 whitespace-nowrap px-6 py-4 text-right text-sm font-medium"
                  >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Edit"
                      placement="top"
                    >
                      <button
                        type="button"
                        class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="{2}"
                            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                          />
                        </svg>
                      </button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="Delete"
                      placement="top"
                    >
                      <button
                        type="button"
                        @click="setDelete(role.Id)"
                        class="focus:outline-none inline-flex items-center rounded-md border border-transparent px-3 py-2 text-sm font-medium leading-4 text-purple-400 shadow-sm hover:text-purple-800 focus:ring-purple-500"
                      >
                        <svg
                          width="18"
                          height="18"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                          />
                        </svg>
                      </button>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>

            <pagination :pagination="pagination" @changePage="onChangePage" />
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="Create Role"
      v-model="drawer.create"
      :direction="direction"
    >
      <el-form class="p-8">
        <el-form-item label="Name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="Deascription">
          <el-input type="textarea" :rows="2" v-model="form.desc"></el-input>
        </el-form-item>
        <el-form-item label="Permissions">
          <el-select
            class="w-full"
            v-model="form.permissions"
            multiple
            placeholder="Select"
          >
            <el-option
              v-for="item in permissions"
              :key="item.Id"
              :label="item.Name"
              :value="item.Id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" class="w-full"
            >Create</el-button
          >
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-dialog
      title="Delete your role"
      custom-class="dialog-custom"
      v-model="dialog.show"
      destroy-on-close
      width="30%"
    >
      <span
        >Once you delete the role, you will lose all data associated with
        it.</span
      >
      <template #footer>
        <span class="dialog-footer">
          <div class="mt-5">
            <button
              type="button"
              @click="onDelete"
              class="focus:outline-none inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:ring-red-500 sm:text-sm"
            >
              Delete
            </button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import Pagination from "@/common/components/pagination";

import API from "@/api";

export default {
  name: "Roles",
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
    Pagination,
  },
  data() {
    return {
      loading: false,
      drawer: {
        create: false,
      },
      pagination: {
        page: 1,
        pageSize: 10,
        totalResults: null,
      },
      dialog: {
        show: false,
        roleId: null,
      },
      direction: "rtl",
      roles: [],
      permissions: [],
      form: {
        name: "",
        desc: "",
        permissions: [],
      },
    };
  },
  created() {
    this.fetchRoles();
    this.fetchPermissions();
  },
  methods: {
    async fetchRoles() {
      try {
        let results = await API.Admin.Roles.get(
          this.pagination.page,
          this.pagination.pageSize
        );
        console.log({ results });
        this.roles = results.data.List;

        this.pagination.page = results.data.Page;
        this.pagination.pageSize = results.data.PageSize;
        this.pagination.totalResults = results.data.TotalResults;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Error while fetching roles. ${error}`,
        });
      }
    },

    async fetchPermissions() {
      try {
        let results = await API.Admin.Permissions.get();
        console.log({ results });
        this.permissions = results.data.List;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: `Error while fetching permissions. ${error}`,
        });
      }
    },

    onSubmit() {
      console.log(this.form);
    },

    onChangePage(page) {
      this.pagination.page = page;

      this.fetchRoles();
    },

    setDelete(id) {
      this.dialog.show = true;
      this.dialog.roleId = id;
    },

    onDelete() {
      API.Admin.Roles.delete(this.dialog.roleId).then(() => {
        this.dialog.show = false;
        this.fetchRoles();
      });
    },
  },
};
</script>

<style scoped>
.layout {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  padding: 24px;
  height: 100%;
  background-color: #fff;
  border-radius: 12px;
}
.p-8 {
  padding: 32px;
}
</style>
